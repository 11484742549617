.container {
    height: 100%;
    overflow: hidden;
}

.item {
    background-color: #ebeff4;
    border-radius: 4px;
    height: 28px;
}

.item + .item {
    margin-top: 4px;
}

.item:first-child {
    margin-bottom: 12px;
    height: 32px;
}

.w75 {
    width: 75%;
}

.w70 {
    width: 75%;
}

.w55 {
    width: 55%;
}

.w50 {
    width: 50%;
}

.w40 {
    width: 40%;
}
