.backdrop,
.root {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    --horisontal-paddings: 28px;
    --col-gap: 16px;
    --total-col-count: 8;
    --categories-col-count: 3;
    --sub-categories-col-count: 5;
    --total-gaps-sum: var(--col-gap) * (var(--total-col-count) - 1);
    --categories-gaps-sum: var(--col-gap) * (var(--categories-col-count) - 1);
    --sub-categories-gaps-sum: var(--col-gap) * var(--sub-categories-col-count);
}

.root {
    z-index: 12;
}

.backdrop {
    background-color: rgba(34, 34, 34, 0.26);
}

.categories {
    width: 256px;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px 4px 12px 12px;
    width: calc(
        (100vw - var(--total-gaps-sum) - var(--horisontal-paddings) * 2 - var(--scrollbar-width)) /
            var(--total-col-count) * var(--categories-col-count) + var(--categories-gaps-sum)
    );
}

.subCategories {
    padding: 12px 4px 12px 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    cursor: default;
    border-left: 1px solid #e6eaf0;
    width: calc(
        (100vw - var(--total-gaps-sum) - var(--horisontal-paddings) * 2 - var(--scrollbar-width)) /
            var(--total-col-count) * var(--sub-categories-col-count) + var(--sub-categories-gaps-sum)
    );
    max-width: 700px;
}

@media (min-width: 1024px) {
    .root {
        --categories-col-count: 2;
        --sub-categories-col-count: 4;
    }
}

@media (min-width: 1280px) {
    .root {
        --total-col-count: 14;
        --categories-col-count: 3;
        --sub-categories-col-count: 8;
    }
}

@media (min-width: 1440px) {
    .root {
        --total-col-count: 14;
        --categories-col-count: 3;
        --sub-categories-col-count: 7;
    }
}

.iconActive {
    display: flex;
    visibility: hidden;
    margin-left: 8px;
}

.active .iconActive {
    visibility: visible;
}

.subCategoriesTitle {
    min-height: 32px;
    text-align: left;
}

.subCategoriesCaption,
.subCategoriesLink {
    display: inline;
}

.subCategoriesLink {
    color: #2754e5;
    margin-left: 8px;
}

.subCategoriesList {
    margin-top: 12px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
}

.subCategoriesListItem {
    padding: 5px 0;
    display: block;
}

.subCategoriesListItem + .subCategoriesListItem {
    margin-top: 4px;
}

.active,
.active .icon,
.subCategoriesListItem:hover {
    color: #547dff;
}

.container {
    background-color: #fff;
    position: absolute;
    height: 80%;
    top: calc(var(--header-height) + 16px);
    left: max(var(--horisontal-paddings), calc(100vw - (var(--header-max-width) - var(--horisontal-paddings) * 2)) / 2);
    display: flex;
    color: #18181b;
    border-radius: 12px;
}

.item,
.icon,
.title {
    display: flex;
    align-items: center;
}

.item {
    padding: 12px 24px 12px 0;
    cursor: pointer;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: #b3b3bc;
}

.iconActive {
    width: 12px;
    height: 11px;
}

.title {
    flex-grow: 1;
}
